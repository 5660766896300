<template>
  <header class="flex-box space-between" :class="{'menu-top' : visible2,'header-scroll':scrollTop,'searchShow':searchactive}">
    <div class="flex-box" v-if="!searchactive">
      <router-link to="/" class="logo">
        <img src="@/assets/images/market/cc-logo2.png" alt="">
      </router-link>
      <div class="header-search flex-box">
        <a-input-search
            class="header-search-input"
            v-model="searchValue"
            placeholder="Search"
            @search="onSearch"
        />
        <img class="header-search-img" src="@/assets/images/market/search.png"/>
      </div>
    </div>
    <div class="nav-right" v-if="isMobile && !searchactive">
      <img src="@/assets/images/market/search_ico.png" alt="" class="search" @click="searchClick">
      <img
          src="~@/assets/images/market/menu-mo.svg"
          @click="visible = true"
          alt=""
      />
    </div>
    <div class="flex-box" v-else-if="!isMobile">
      <div class="header-menu">
        <a
            v-for="(item, index) in list"
            :key="index"
            :class="{ active: $route.path == item.url }"
            @click="navClick(item)"
        >
          <span class="gyrox-nav-text pd-r4">{{ item.text }}</span>
        </a>
      </div>
      <meadow-wallet></meadow-wallet>
      <img
          src="~@/assets/images/market/menu-mo.svg"
          @click="visible2 = true"
          alt=""
          class="menu-img"
          v-if="!visible2"
      />
      <img
            v-else
            class="menu-img"
            @click="visible2 = false"
            src="~@/assets/images/market/close.svg"
        />
    </div>
    <!-- search -->
    <div class="seach_show"  v-if="isMobile && searchactive">
      <div class="back" @click="searchClick">
        <img src="@/assets/images/market-detail/back_mo.png" alt="">
      </div>
        <input type="search" class="search" placeholder="Search Meadowfair" ref="input" @search="onSearch">
    </div>
    <!-- end -->
    <a-drawer
        wrapClassName="mobile-menu"
        :visible="visible"
        :closable="false"
        @close="visible = false"
        width="70%"
    >
      <div class="mobile-close">
        <img
            @click="visible = false"
            src="~@/assets/images/market/close.svg"
        />
      </div>
      <meadow-wallet></meadow-wallet>
      <a-menu mode="inline" :inlineIndent="0" class="menu-list">
        <a-menu-item v-for="item in list" :key="item.text">
          <a @click="navClick(item)">{{ item.text }}</a>
        </a-menu-item>
      </a-menu>
    </a-drawer>
    <a-drawer
        wrapClassName="pc-menu"
        :visible="visible2"
        :closable="false"
        @close="visible2 = false"
        width="15%"
    >
      <a-menu mode="inline" :inlineIndent="0" class="menu-list">
        <a-menu-item v-for="item in list" :key="item.text">
          <a @click="navClick(item)">{{ item.text }}</a>
        </a-menu-item>
      </a-menu>
    </a-drawer>
  </header>
</template>

<script>
import { mapState } from "vuex";
import meadowWallet from "./meadow-wallet";
export default {
  name: "navContent",
  components: {
    meadowWallet
  },
  data() {
    return {
      list: [
        {
          url: "/search",
          text: "Explore"
        },
        {
          url: "/offer-price",
          text: "Offer Price"
        },
        {
          url: "/account",
          text: "My NFT"
        },
      ],
      searchValue:"",
      visible:false,
      visible2:false,
      scrollTop:0,
      searchactive:false,
    };
  },
  computed: {
    ...mapState({
      device: (state) => state.device,
      account: (state) => state.account,
      chainId: (state) => state.chainId,
      isMobile:(state) => state.isMobile
    }),
  },
  mounted() {
    let self = this;
    window.addEventListener("resize",function (){
      self.visible = false
      self.visible2 = false
    },false)
    window.addEventListener('scroll', function (){
      self.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    });
    this.searchValue = this.$route.query.keyword || ""
  },
  methods: {
    navClick(item) {
      this.visible = false;
      this.visible2 = false;
      let url = item.url;
      if(!this.account){
        if(url != '/search'){
          this.$store.dispatch('connected');
          return
        }
      }
      if(url == '/account'){
        url = url + '/' + this.account;
      }
      this.$router.push(url);
    },
    onSearch(){
        this.$router.push({path: '/search', query: {keyword: this.searchValue}})
    },
    searchClick(){
      if(this.searchactive){
        this.searchactive = false
      }else{
        this.searchactive = true
        setTimeout(()=>{
          this.$refs.input.focus()
        },0)
      }
    }
  },
  watch:{
    '$route.path':{
      handler(newVal){
        if(newVal == '/search'){
          return
        }else{
          this.searchValue = ""
        }
      },
      deep: true
    }
  }
};
</script>
<style lang='scss'>
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
.ant-drawer-body{
  padding:0.52rem 0.4rem 0;
}
.ant-drawer-content{
  background: #000000;
}
.mobile-menu {
  .mobile-close{
    display: flex;
    justify-content: flex-end;
    img{
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .ant-dropdown-menu-item {
    padding: 10px !important;
  }
  .ant-menu-submenu-arrow {
    right: 25px !important;
  }
  .ant-menu-submenu-arrow:hover,
  .ant-menu-submenu-arrow:after,
  .ant-menu-submenu-arrow:before {
    background: #0e1d51 !important;
  }
  .ant-menu-inline{
    padding-top: 0.38rem;
    background: transparent;
    border: none;
    .ant-menu-item{
      margin: 0;
      font-size: 0.32rem;
      height: 1.06rem;
      line-height: 1.06rem;
      color: #FFFFFF;
      font-family: "SFPixelate-Bold";
      background-color:transparent !important;
      a{
        color: #FFFFFF;
        &:hover{
          color: #01C67F;
        }
      }
    }
  }
  &:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: transparent;
    transition: none;
    &::after{
      display: none;
    }
  }
}
.pc-menu{
  .ant-drawer-content{
    background-color: #0B2E33;
    margin-top: 72px;
    height: calc(100% - 72px) !important;
  }
  .ant-dropdown-menu-item {
    padding: 10px !important;
  }
  .ant-menu-submenu-arrow {
    right: 25px !important;
  }
  .ant-menu-submenu-arrow:hover,
  .ant-menu-submenu-arrow:after,
  .ant-menu-submenu-arrow:before {
    background: #0e1d51 !important;
  }
  .ant-menu-inline{
    background-color:transparent;
    border: none;
    .ant-menu-item{
    font-size: 16px;
    font-family: 'SFPixelate-Bold';
    border: none;
    text-align: center;
    background-color:transparent !important;
      a{
        color: #FFFFFF;
        &:hover{
          color: #01C67F;
        }
      }
    }
    &:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color:transparent;
    &::after{
      display: none;
    }
  }
  }
}
.nav-right{
  img{
    width: .4rem;
    height: .4rem;
  }
}
</style>
<style lang='scss' scoped>
header{
  padding:16px 7.3%;
  min-width: 1200px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: rgba(12, 45, 51, 0);
  width: 100%;
  z-index: 1000;
  &.header-scroll{
    background: rgba(12, 45, 51, 1);
  }
  .logo {
    width: 316px;
    height: 36px;
    display: block;
    img {
      width: 100%;
    }
  }
  .header-search{
    margin-left: 12px;
    position: relative;
    .header-search-img{
      position: absolute;
      width: 16.88px;
      height: 16.88px;
      left: 24px;
    }
    :deep(.ant-input-search){
      &:hover {
        input {
          border-color:red;
        }
      }
      input{
        width: 274px;
        height: 40px;
        padding-left: 50px;
        font-size: 16px;
        border: none;
        box-shadow: none;
        background: url("~@/assets/images/market/search-bg.svg") no-repeat;
        color: #FFFFFF;
        &::placeholder{
          color: rgba(1,198,127,0.3);
        }
      }
      .ant-input-search-icon{
        display: none;
      }
    }
  }
  .header-menu{
    a{
      font-family: "SFPixelate-Bold";
      font-size: 16px;
      padding:0 16px;
      color: #FFFFFF;
      transition: all 0.2s cubic-bezier(0.05,0,0.2,1);
      &:hover{
        color: #01C67F;
      }
    }
  }
  .menu-img{
      display: none;
      margin-left: 12px;
      cursor: pointer;
      width: 25px;
      height: 25px;
  }
}
@media screen and (max-width: 750px) {
  header{
    padding:0.5rem 0.4rem .48rem;
    min-width: auto;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.36);
    .logo {
      width: 4rem;
      height: .46rem;
    }
    .header-search{
      display: none;
      margin-left: .2rem;
      position: relative;
      .header-search-img{
        position: absolute;
        width: .34rem;
        height: .34rem;
        left: .5rem;
      }
      :deep(.ant-input-search){
        input{
          width: 3.56rem;
          height: .8rem;
          padding-left: .9rem;
          font-size: .24rem;
          background: url("~@/assets/images/market/search-bg-mo.svg") no-repeat;
          background-size: contain;
        }
      }
    }
    .header-menu{
      a{
        font-family: "SFPixelate-Bold";
        font-size: 16px;
        padding:0 16px;
        color: #FFFFFF;
        &:hover{
          color: #01C67F;
        }
      }
    }
    .nav-right{
      .search{
        width: .4rem;
        height: .4rem;
        margin-right: .36rem;
        cursor: pointer;
      }
    }
    .seach_show{
      width: 100%;
      height: 1.44rem;
      display: flex;
      .back{
        width: 14%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
          width: .4rem;
          height: .4rem;
        }
      }
      .search{
        padding: .58rem 0;
        padding-right: .3rem;
        background: transparent;
        border: none;
        color: #FFFFFF;
        font-size: .24rem;
        font-family: "SFPixelate-Bold";
        width: 100%;
        &::placeholder{
          color: #527171;
        }
      }
    }
  }
  .searchShow{
      padding: 0;
    }
}
@media screen and (min-width: 750px) and (max-width: 1280px){
  header{
    padding: 16px 3%;
    .header-search{
      margin-left: 12px;
    }
  }
  .menu-top{
    background-color: #0B2E33;
    width: 100%;
    border-bottom: 1px solid #046E4E;
    z-index: 9999;
  }
  .header-menu{
    display: none;
  }
  .menu-img{
    display: block !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1640px){
  header{
    padding: 16px 3%;
    .logo{
      width: 220px;
      display: flex;
      align-items: center;
    }
    .header-search{
      margin-left: 12px;
    }
  }
}
</style>
