<template>
  <div class="home-footer" ref="foot">
    <div class="home-footer-info">
      <div class="home-footer-left">
        <a class="home-footer-logo" href="/" rel="nofollow" target="_blank"><img src="@/assets/images/market/cc-logo.png" /></a>
        <ul>
          <li>
            <a href="https://twitter.com/meadowfair_io" rel="nofollow" target="_blank">
            </a>
          </li>
          <li>
            <a href="https://t.me/meadowfair" rel="nofollow" target="_blank">
            </a>
          </li>
          <li>
            <a href="https://medium.com/@meadowfair" rel="nofollow" target="_blank">
            </a>
          </li>
        </ul>
      </div>
      <div class="home-footer-right">
        <!-- Trading Methods< -->
        <ul>
          <li>Trading Methods</li>
          <li @click="goSearchTrade('trade')">
            <a>
              Trade
            </a>
          </li>
          <li @click="goSearchTrade('rental')">
            <a>
              Rental
            </a>
          </li>
          <li @click="goSearchTrade('financial-lease')">
            <a>
              Financial Lease
            </a>
          </li>
          <li @click="goSearchTrade('fractionalization')">
            <a>
              Fractionalization
            </a>
          </li>
          <li @click="goSearchTrade('lending')">
            <a>
              Lending
            </a>
          </li>
          <li @click="goSearchTrade('barter')">
            <a>
              Barter
            </a>
          </li>
        </ul>
        <!-- Categories -->
        <ul>
          <li>Categories</li>
          <li @click="goSearchcategory('Digital Art')">
            <a>
              Digital Art
            </a>
          </li>
          <li @click="goSearchcategory('Metaverse')">
            <a>
              Metaverse
            </a>
          </li>
          <li @click="goSearchcategory('PASSes & Rights')">
            <a>
              PASSes & Rights
            </a>
          </li>
          <li @click="goSearchcategory('Real-World-Asset')">
            <a>
              Real-World-Asset
            </a>
          </li>
          <li @click="goSearchcategory('DeFi')">
            <a>
              DeFi
            </a>
          </li>
          <li @click="goSearchcategory('Yield')">
            <a>
              Yield
            </a>
          </li>
        </ul>
        <!-- Standards -->
        <ul>
          <li>Standards</li>
          <li @click="goSearchstandard('ERC-721')">
            <a>
              ERC-721
            </a>
          </li>
          <li @click="goSearchstandard('ERC-1155')">
            <a>
              ERC-1155
            </a>
          </li>
          <li @click="goSearchstandard('ERC-4907')">
            <a>
              ERC-4907
            </a>
          </li>
          <li @click="goSearchstandard('ERC-3525')">
            <a>
              ERC-3525
            </a>
          </li>
          <li @click="goSearchstandard('ERC-5187')">
            <a>
              ERC-5187
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-footer-bottom">
      <div>
        <p>&copy;2024 MeadowFair</p>
        <p>Docs | Disclaimer | Privacy Policy</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeFooter",
  methods: {
    goAbout() {
      this.$parent.$refs["about"].scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    goRoadMap() {
      this.$parent.$refs["roadMap"].scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
    comingSoon() {
      this.$notification["info"]({
        message: "Coming Soon",
      });
    },
    goSearchTrade(val){
      location.href = `/search?tradingMethods=`+val
    },
    goSearchcategory(val){
      location.href = `/search?categories=`+encodeURIComponent(val)
      console.log(val);
    },
    goSearchstandard(val){
      location.href = `/search?standards=`+val
    }
  },
};
</script>

<style lang="scss" scoped>
.home-popover{
  p{
    display: flex;
    a{
      color: #000000;
      min-width: 120px;
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover{
        font-size: 16px;
        background: rgba(230, 247, 255, 1);
        font-family: "AvertaStd-Semibold";
      }
    }
  }
}
.home-footer {
  height: 444px;
  background: #000000;
  padding: 0 7.5%;
  .home-footer-info{
    display: flex;
    justify-content: space-between;
    padding-top: 54px;
    .home-footer-left{
      .home-footer-logo {
        width: 220px;
        display: block;
        img{
          width: 100%;
        }
      }
      ul{
        display: flex;
        margin-top:42px;
        li{
          margin-right: 12px;
          a{
            width: 40px;
            height: 40px;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &:nth-child(1){
            a{
              background-image: url("~@/assets/images/market/twitter.svg");
              &:hover{
                background-image: url("~@/assets/images/market/twitter-hover.svg");
              }
            }
          }
          &:nth-child(2){
            a{
              background-image: url("~@/assets/images/market/faq.svg");
              &:hover{
                background-image: url("~@/assets/images/market/faq-hover.svg");
              }
            }
          }
          &:nth-child(3){
            a{
              background-image: url("~@/assets/images/market/medium.svg");
              &:hover{
                background-image: url("~@/assets/images/market/medium-hover.svg");
              }
            }
          }

        }
      }
    }
    .home-footer-right{
      display: flex;
      height: 318px;
      ul{
        width: 200px;
        margin-right:80px;
        &:last-child{
          margin-right:133px;
        }
        li{
          font-size: 14px;
          font-family: "SFPixelate-Bold";
          color: #757575;
          margin-top:18px;
          cursor: default;
          &:first-child{
            font-size: 18px;
            line-height: 20px;
            margin:10px 0 18px;
            color: #757575;
          }
          a{
            font-size: 14px;
            color: rgba(1,198,127,0.6);
            &:hover{
              color: #01C67F;
            }
          }
        }
      }
    }
  }
  .home-footer-bottom{
    border-top:1px solid #363636;
    div{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p{
      font-size: 12px;
      line-height: 15px;
      padding-top: 24px;
      color: #757575;
      font-family: "Lucida-Grande-Bold";
      a{
        color: #757575;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .home-footer {
    height: auto;
    background: #000000;
    padding: 0 0.4rem;
    .home-footer-info{
      display: block;
      padding-top: .72rem;
      .home-footer-left{
        .home-footer-logo {
          width: 4.4rem;
          height: .5rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
        ul{
          margin-top:0.48rem;
          li{
            margin-right: 0.24rem;
            a{
              width: .8rem;
              height: .8rem;
            }
          }
        }
      }
      .home-footer-right{
        display: flex;
        flex-wrap: wrap;
        height: auto;
        padding-bottom: .8rem;
        ul{
          width: 2.92rem;
          margin-right: 0;
          &:first-child{
            margin-right:.82rem;
            li{
              &:first-child{
                padding-bottom: 0.04rem;
              }
            }
          }
          li{
            font-size: .28rem;
            margin-top:.36rem;
            &:first-child{
              font-size: .36rem;
              line-height: .4rem;
              margin:1rem 0 0;
              padding-bottom: 0.44rem;
            }
            a{
              font-size: .28rem;
            }
          }
        }
      }
    }
    .home-footer-bottom{
      border-top:1px solid #363636;
      div{
        display: block;
        padding-bottom: .64rem;
      }
      p{
        font-size: .24rem;
        line-height: .3rem;
        padding-top: .48rem;
        text-align: center;
        a{
          color: #757575;
        }
        &:last-child{
          padding-top: .24rem;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) and (max-width: 1280px) {
  .home-footer{
    padding: 0 3%;
    .home-footer-info{
      .home-footer-right{
        ul {
          margin-right: 0px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px){
  .home-footer{
    padding: 0 6%;
    .home-footer-info{
      .home-footer-right{
        ul {
          margin-right: 40px;
          &:last-child {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
</style>
