<template>
  <div class="flex-box wallet-box">
    <div class="meadow-btn meadow-btn-p" @click="balanceClick">
      <p>
        <span></span>
        {{ userBalance | decimals}}
      </p>
    </div>
    <div class="wallet-network flex-box">
      <a-select
          class="change-network"
          dropdownClassName="drop-network"
          :value="desireChainId"
          @change="switchNetwork"
      >
        <a-select-option
            v-for="item in networkObj"
            :key="item.chainId"
            :value="item.chainId"
            :disabled="item.disabled"
        >
          <div class="wallet-network-icon flex-box space-center">
            <img :src="`/img/${item.nativeCurrency.name}.png`"/>
          </div>
        </a-select-option>
      </a-select>
      <template v-if="account">
        <template v-if="desireChainId != chainId">
          <div class="meadow-btn">
            <a-button class="meadow-btn-wn" @click="switchNetwork(desireChainId)">Wrong Network</a-button>
          </div>
        </template>
        <template v-else>
          <a-popover placement="bottomRight">
            <div class="meadow-btn">
              <a-button :loading="connectedLoading" class="active-button">
                {{ account|showAddress}}
              </a-button>
            </div>
            <template #content>
              <p @click="disConnect" class="color-0E1D51 ts-16 cursor">
                <a-icon type="disconnect" />
                <span class="pd-l8">Disconnect wallet</span>
              </p>
            </template>
          </a-popover>
        </template>
      </template>
      <div class="meadow-btn" v-else>
        <a-button class="connection" @click="onConnected" :loading="connectedLoading"> Connect </a-button>
      </div>
    </div>
    <a-modal
        v-model="signModalVisible"
        centered
        :footer="null"
        :maskClosable = false
        class="authorization_box"
        :width = 610
    >

      <h3>welcome to MeadowFair</h3>
      <img src="@/assets/images/market/meadowfair-logo.png" alt="">
      <p>By connecting your wallet and using MeadowFair,
        you agree to our <a>Disclaimer</a> and <a>Privacy Policy</a> </p>
      <div class="btns">
        <div class="cancel" @click="cancelModal">
          Cancel
        </div>
        <div class="accept" @click="addAccept">
          Accept and sign
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { Network } from '@/config/constants';
import { getStorage,setStorage } from '@/utils';
import nftSdk from "@/sdk";
import {userSign} from "@/api";
import detectEthereumProvider from '@metamask/detect-provider';
export default {
    data() {
        return {
            connectedLoading: false,
            networkObj: Network
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.account,
            isMobile: (state) => state.isMobile,
            chainId: (state) => state.chainId,
            desireChainId: (state) => state.desireChainId,
            userBalance: (state) => state.userBalance,
            signModalVisible:(state) => state.signModalVisible,
            loginOut:(state) => state.loginOut,
        })
    },
    methods: {
        ...mapActions(['connected', 'updateAccount', 'switchNetwork']),
        disConnect() {
            this.updateAccount(null);
        },
        onConnected() {
            if (!(typeof window.ethereum !== 'undefined')) {
                message.error('install MetaMask first!');
            } else {
                this.connectedLoading = true;
                this.connected()
                    .then(async () => {
                        if (this.chainId != this.desireChainId) {
                            await this.switchNetwork(this.desireChainId);
                        }
                        this.connectedLoading = false;
                    })
                    .catch((err) => {
                        if (err.code === -32002) {
                            message.error(this.$t('wallet.openWallet'));
                        }
                        this.connectedLoading = false;
                    });
            }
        },
        balanceClick(){
          if(!this.account){
            this.onConnected()
          }
        },
        addAccept(){
          let signAccount = getStorage("signAccount");
          if(!signAccount)return;
          this.$store.dispatch('updateSignModalVisible',false);
          let signer = nftSdk.getSigner();
          const msg = 'By using Medowfair, you agree to the Terms of Service.';
          signer.signMessage(msg)
              .then((sign) => {
                userSign({'address':signAccount,'sign':sign}).then(({ code }) => {
                  if (code != 0) {
                    return;
                  }
                  this.$store.dispatch('connected');
                  setStorage("signAccount","");
                })
              })
        },
        cancelModal(){
          this.$store.dispatch('updateSignModalVisible',false);
        }
    },
    created() {},
    mounted() {
        let default_chainId;
        for (let _chainId in Network) {
            default_chainId = _chainId;
            break;
        }
        this.$store.commit("SET_DESIRE_CHAINID", default_chainId);
        detectEthereumProvider()
            .then(async (provider) => {
                if (!provider) {
                    return;
                }
                const ethereum = window.ethereum;
                ethereum.on('disconnect', () => {
                    this.updateAccount(null);
                });
                ethereum.on('chainChanged', (chainId) => {
                    this.$store.commit('SET_CHAINID', chainId);
                    console.log(this.account);
                    if (this.networkObj[chainId]) {
                        window.location.reload();
                    }
                });
                ethereum.on('accountsChanged', (accounts) => {
                    if (accounts.length > 0) {
                        this.account && this.updateAccount(accounts[0]);
                    } else {
                        this.updateAccount(null);
                    }
                });
                let chainId = await ethereum.request({
                    method: 'eth_chainId',
                });
                this.$store.commit('SET_CHAINID', chainId);
                if (this.networkObj[chainId]) {
                    this.$store.commit('SET_DESIRE_CHAINID', chainId);
                } else {
                    this.$store.commit('SET_DESIRE_CHAINID', default_chainId);
                }
                if(!this.loginOut){
                  ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                    if (accounts && accounts.length > 0) {
                      this.$store.dispatch('connected');
                    }
                  });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
</script>
<style lang="scss">
.ant-select-dropdown{
  padding:0;
  box-shadow: none;
  border: none;
}
.authorization_box{
  .ant-modal-content{
    height: 391px;
    padding: 0;
    margin: 0;
    background: url("~@/assets/images/market/authorization_bg.svg") no-repeat;
    .ant-modal-close-x{
      display: none;
    }
    .ant-modal-body{
      padding: 0 91px;
      text-align: center;
      font-family: "SFPixelate-Bold";
      h3{
      font-size: 20px;
      color: #FFCE01;
      line-height: 22px;
      text-shadow: 0px 3px 0px #000000;
      padding-top: 46px;
      }
      img{
        width: 246px;
        height: 28px;
        margin: 40px 0;
      }
      p{
        font-size: 14px;
        color: #FFFFFF;
        line-height: 22px;
        a{
          color: #008B60;
          &:hover{
            color: #01C67F;
          }
        }
      }
      .btns{
        display: flex;
        line-height: 17px;
        font-size: 16px;
        margin-top: 63px;
        .cancel{
          width: 113px;
          height: 41px;
          margin: 0 52px 67px 28px;
          background: url("~@/assets/images/market/Cancel_btn.svg") no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #01C67F;
          cursor: pointer;
        }
        .accept{
          width: 208px;
          height: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          cursor: pointer;
          background: url("~@/assets/images/market/accept_btn.svg") no-repeat;
        }
      }
     }
  }
}
@media screen and (max-width: 750px) {
  .authorization_box {
    .ant-modal{
      width: 7.1rem !important ;
    }
    .ant-modal-content{
    height: 7.82rem;
    background: url("~@/assets/images/market/authorization_mo.svg") no-repeat ;
    background-size: contain;
    .ant-modal-body{
      padding: 0 .24rem;
      h3{
        font-size: .4rem;
        line-height: .44rem;
        padding-top: .92rem;
      }
      img{
        width: 4.92rem;
        height: .56rem;
        margin: .8rem 0 .74rem;
      }
      p{
        font-size: .28rem;
        line-height: .44rem;
      }
      .btns{
        margin-top: .68rem;
        font-size: .32rem;
        line-height: .34rem;
         .cancel{
          width: 2.02rem;
          height: .82rem;
          background: url("~@/assets/images/market/Cancel_mo.svg") no-repeat;
          background-size: contain;
          margin: 0 .36rem 0 .16rem;
         }
         .accept{
          width: 3.92rem;
          height: .82rem;
          font-size: .32rem;
          line-height: .34rem;
          background: url("~@/assets/images/market/accept_mo.svg") no-repeat;
          background-size: contain;
         }
      }
    }
    }
  }


}
</style>
<style lang="scss" scoped>
.meadow-btn {
    position: relative;
    padding: 0 22px;
    display: flex;
    opacity: .6;
    &:hover{
      opacity: 1;
    }
    &-p {
      height: 40px;
      margin-left: 12px;
      &:hover{
        opacity: .6;
      }
      p {
        padding:0 2px;
        height: 40px;
        color: #000000;
        background: #01C67F;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 17px;
          height: 15px;
          background: url('~@/assets/images/market/balance.svg') no-repeat;
          margin-right: 6px;
        }
      }
    }
    &:before {
        width: 24px;
        height: 40px;
        content: '';
        background: url('~@/assets/images/market/btn-bg-l.png') no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
    }
    &:after {
        width: 24px;
        height: 40px;
        content: '';
        background: url('~@/assets/images/market/btn-bg-r.png') no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 0;
    }
    button {
        margin: 0;
        padding: 0 2px;
        border-radius: 0;
        box-shadow: none;
        height: 40px;
        color: #000000;
        background: #01C67F;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        border: none;
        &:hover,
        &:focus,
        &:active,
        .active-button{
            background: #01C67F;
            color: #000000;
        }
        &:after,&:before{
          display: none;
        }
    }
}
.change-network {
    margin: 0 12px;
    width: 40px;
    height: 40px;
    border: none;
    background: url('~@/assets/images/market/network-bg.svg') no-repeat;
    .wallet-network-icon{
      img{
        width: 20px;
        height: 20px;
      }
    }
  :deep(.ant-select-selection--single){
    height: 40px;
    border: none;
    background: transparent;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-select-selection__rendered{
      margin: 0;
      line-height:normal;
    }
    .ant-select-arrow{
      display: none;
    }
  }
}
.drop-network{
  .wallet-network-icon{
    img{
      width: 20px;
      height: 20px;
    }
  }
}
@media screen and (max-width: 750px){
  .wallet-box{
    padding-top: 1.12rem;
    display: block;
    .meadow-btn{
      padding:0 0.4rem;
      &-p {
        height: .8rem;
        margin-left:0;
        p {
          flex:1;
          height: .8rem;
          font-size: 0.32rem;
          span {
            display: block;
            width: 0.34rem;
            height: 0.3rem;
            background-size: contain;
            margin-right: 0.12rem;
          }
        }
      }
      &:before {
        width: 0.4rem;
        height: 0.8rem;
        background: url('~@/assets/images/market/btn-bg-lm.png') no-repeat;
        background-size: contain;
      }
      &:after {
        width: 0.4rem;
        height: 0.8rem;
        background: url('~@/assets/images/market/btn-bg-rm.png') no-repeat;
        background-size: contain;
      }
      button {
        height: .8rem;
        font-size: .32rem;
        &.meadow-btn-wn{
          font-size: .24rem;
        }
      }
    }
    .wallet-network{
      padding-top: 0.38rem;
      justify-content: space-between;
      .change-network{
        width: .8rem;
        height: .8rem;
        margin: 0;
        background-size: contain;
        flex: none;
        :deep(.ant-select-selection--single){
          height: .8rem;
        }
        .wallet-network-icon{
          img{
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }
      .meadow-btn{
        margin-left: 0.24rem;
        flex: 1;
        button{
          flex: 1;
        }
      }
    }
  }
  .drop-network{
    .wallet-network-icon{
      img{
        width: 0.4rem;
        height: 0.4rem;
      }
    }
  }
}
</style>
