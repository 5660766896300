import store from '@/store'
export function mobileRem() {
  size();
  if (window.addEventListener) {
      return window.addEventListener('resize', () => {
          size();
      }, false);
  } else if (window.attachEvent) {
      return window.attachEvent('onresize', () => {
          size();
      });
  }
  function size() {
      let winW = document.documentElement.clientWidth || document.body.clientWidth;
      if (winW > 750) {
          store.dispatch('updateMobile',false)
          document.documentElement.style.fontSize = "16px";
      } else {
          store.dispatch('updateMobile',true)
          document.documentElement.style.fontSize = (winW / 750) * 100 + "px";
      }
  }
}
export default { mobileRem }
