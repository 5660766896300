<template>
  <div class="meadow-box" :class="{'meadow-market-box':$route.name=='market'}">
    <app-nav></app-nav>
    <a-config-provider>
      <template #renderEmpty> </template>
    <section>
      <router-view />
    </section>
    </a-config-provider>
    <footer-content></footer-content>
  </div>
</template>
<script>
import appNav from "@/views/components/nav";
import footerContent from "@/views/components/footer";
import { mobileRem } from "@/utils/rem";
export default {
  components: {
    appNav,
    footerContent,
  },
  mounted() {
    mobileRem();
    console.log(this.$route.name)
  }
};
</script>
<style scoped lang="scss">
.meadow-box{
  background:#0C2D33;
  &.meadow-market-box{
    background: url("~@/assets/images/market/market-bg.svg") no-repeat center 32px #0C2D33;
  }
  @media screen and (min-width: 750px) and (max-width: 1280px){
    min-width: 1200px;
  }
}
</style>
